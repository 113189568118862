import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
//import { ToastContainer, toast } from "react-toastify";
import "./Home.css";
import LinuxFolderStructure from "./LinuxFolderStructure";
import TaskQA from "./TaskQA";
import LinuxFolderStructureChoice from "./LinuxFolderStructureChoice";
import TaskQAChoice from "./TaskQAChoice";
import LinuxFolderStructureNoChoice from "./LinuxFolderStructureNoChoice";
import TaskQANoChoice from "./TaskQANoChoice";
import SystemVulnerabilities from "./SystemVulnerabilities";
import SystemVulnerabilitiesRand from "./SystemVulnerabilitiesRand";

const Round = () => {
  const apiUrl = process.env.REACT_APP_API_URL || "https://mipha.cs.utep.edu";
  const location = useLocation();
  const navigate = useNavigate();
  const rNumber = location.state.roundNumber;
  /*
  const [roundNumberAssignedSet, setRoundNumberAssignedSet] = location.state.roundNumberAssignedSet;
  */
  const totalPoints = location.state.totalPoints;
  const [colorConditionChosen, setColorConditionChosen] = useState("");
  const [difficultyConditionChosen, setDifficultyConditionChosen] = useState("");

  //console.log(location);
  //console.log(rNumber);
  const [cookies, removeCookie] = useCookies([]);
  const [username, setUsername] = useState("");
  const [congruent, setCongruent] = useState("");


  useEffect(() => {
    const handlePopState = () => {
      window.history.go(1);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const STORAGE_KEY = "RoundSet"; // Key for local storage
  const defaultArray = [1, 2, 3, 4];

  const getStoredArray = () => {
    const stored = localStorage.getItem(STORAGE_KEY);
    return stored ? JSON.parse(stored) : defaultArray;
  };

  const [roundNumberAssignedSet, setRoundNumberAssignedSet] = useState(getStoredArray);



  useEffect(() => {

    const setCondition = () => {
      let num = randomNumberGenerator(0, roundNumberAssignedSet.length - 1);

      let pkt = roundNumberAssignedSet[num];

      //for splicing aka setRoundNumberAssignedSet(roundNumberAssignedSet.splice(num, 1));
      const tempRoundSet = [...roundNumberAssignedSet];

      tempRoundSet.splice(num, 1);

      console.log(tempRoundSet + " and " + roundNumberAssignedSet);

      setRoundNumberAssignedSet(tempRoundSet);

      localStorage.setItem(STORAGE_KEY, JSON.stringify(tempRoundSet));
      /*
      roundNumberAssignedSet at 1 
      choice
      PR = E/H
      PC = E/H
      Minimum
      Roundnumber = 1/2/3/4
  
      roundNumberAssignedSet at 2 
      choice
      PR = E/H
      PC = E/H
      Non color
      Roundnumber = 1/2/3/4
  
      roundNumberAssignedSet at 3 
      no choice
      PR = H or PR = E/H
      PC = H or PC = E/H
      Minimum
      Roundnumber = 2/4 or 1/2/3/4
  
      roundNumberAssignedSet at 4 
      no choice
      PR = E or PR = E/H
      PC = E or PC = E/H
      Minimum
      Roundnumber = 1/3 or 1/2/3/4
  
        */

      let colorCondition = ["Neutral", "Minimum"];
      let difficultyCondition = ["Choice", "No Choice"];

      if (pkt == 1) {
        setColorConditionChosen(colorCondition[1]);
        setDifficultyConditionChosen(difficultyCondition[0]);
        setRoundNumberAssigned(randomNumberGenerator(1, 4))
      } else if (pkt == 2) {
        setColorConditionChosen(colorCondition[0]);
        setDifficultyConditionChosen(difficultyCondition[0]);
        setRoundNumberAssigned(randomNumberGenerator(1, 4))
      } else if (pkt == 3) {
        setColorConditionChosen(colorCondition[1]);
        setDifficultyConditionChosen(difficultyCondition[1]);
        setRoundNumberAssigned(randomNumberGenerator(1, 4))
      } else if (pkt == 4) {
        setColorConditionChosen(colorCondition[0]);
        setDifficultyConditionChosen(difficultyCondition[1]);
        setRoundNumberAssigned(randomNumberGenerator(1, 4))
      } else {
        console.log("Error in pkt Handle");
      }

    console.log(pkt);
    console.log(tempRoundSet);
    console.log(tempRoundSet.length);
    console.log(colorConditionChosen);
    console.log(difficultyConditionChosen);

    };

    setCondition();

  }, []);

  useEffect(() => {

    const verifyCookie = async () => {
      /* if (!cookies.token) {
        navigate("/login");
      } */
      const { data } = await axios.post(
        `${apiUrl}/ver`,
        {},
        { withCredentials: true }
      );
      const { status, user, congruent } = data;
      setUsername(user);
      setCongruent(congruent);

      return status
        ? console.log(`Hello ${user}`)
        : (removeCookie("token"), navigate("/login"));
    };
    console.log("round points " + totalPoints);
    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  const randomNumberGenerator = (min = 0, max = 9) => {
    if (min > max) {
      throw new Error('Min should be less than or equal to Max');
    }
    let num = Math.floor(Math.random() * (max - min + 1)) + min;
    return num;
  };

  const Logout = () => {
    removeCookie("token");
    navigate("/signup");
  };

  const HandleGame = () => {
    console.log("current congruent number" + congruent);

    if (congruent == '1') {
      navigate("/gameP9b0c", {
        state: {
          roundNumber,
          lFolderStructure,
          taskQuestionSolution,
          systemVulnerabilitiesList,
          totalPoints,
          colorConditionChosen,
          difficultyConditionChosen,
          roundNumberAssignedSet,
        },
      });
    } else if (congruent == '2') {
      navigate("/gameMbUC6", {
        state: {
          roundNumber,
          lFolderStructure,
          taskQuestionSolution,
          systemVulnerabilitiesList,
          totalPoints,
          colorConditionChosen,
          difficultyConditionChosen,
          roundNumberAssignedSet,
        },
      });
    } else if (congruent == '3') {
      navigate("/game06LIr", {
        state: {
          roundNumber,
          lFolderStructure,
          taskQuestionSolution,
          systemVulnerabilitiesList,
          totalPoints,
          colorConditionChosen,
          difficultyConditionChosen,
          roundNumberAssignedSet,
        },
      });
    }

  };

  /* const Game1 = () => {
     //console.log("This worked second game worked first");
 
     let newRoundNumberAssignedSet = roundNumberAssignedSet;
     newRoundNumberAssignedSet.splice(roundNumberIndex, 1);
     //console.log("************", newRoundNumberAssignedSet);
     navigate("/game1", {
       state: {
         roundNumber,
         lFolderStructure,
         taskQuestionSolution,
         systemVulnerabilitiesList,
         totalPoints,
         colorConditionChosen,
         difficultyConditionChosen,
         newRoundNumberAssignedSet,
       },
     });
   };*/

  /*


  console.log(roundNumber1);
  console.log(roundNumberAssignedSet[roundNumber1]);

  */

  /*const [roundNumberAssignedSetCurrent, setRoundNumberAssignedSetCurrent] =
    useState(roundNumberAssignedSet);*/
  const [roundNumber, setRoundNumber] = useState(rNumber);
  const [roundNumberAssigned, setRoundNumberAssigned] = useState(0);
  const [lFolderStructure, setLFolderStructure] = useState({});
  const [taskQuestionSolution, setTaskQuestionSolution] = useState({});
  const [systemVulnerabilitiesList, setSystemVulnerabilitiesList] = useState(
    {}
  );

  /*const removeRoundNumber = (roundNumberToRemove) => {
    setRoundNumberAssignedSetCurrent((prevRoundNumber) =>
      prevRoundNumber.filter(
        (roundNumber) => roundNumber !== roundNumberToRemove
      )
    );
  };*/

  /*
  const roundGame = () => {
    Game1();
  };
  */

  const handleFolderData = (dataFromLinuxFolderStructure) => {
    setLFolderStructure(dataFromLinuxFolderStructure);
  };

  const handleTaskData = (dataFromTaskQA) => {
    setTaskQuestionSolution(dataFromTaskQA);
  };

  const handleVulnerabilitiesData = (dataFromSystemVulnerabilities) => {
    setSystemVulnerabilitiesList(dataFromSystemVulnerabilities);
  };

  return (
    <>
      <div className="home_page">
        <h4>
          {" "}
          Round <span>{roundNumber}</span>
        </h4>
        {difficultyConditionChosen === "Choice" && (
          <LinuxFolderStructureChoice
            roundNum={roundNumberAssigned}
            autoAssignLFS={handleFolderData}
          />
        )}
        {difficultyConditionChosen === "Choice" && (
          <TaskQAChoice
            roundNum={roundNumberAssigned}
            assignTaskQA={handleTaskData}
          />
        )}
        {difficultyConditionChosen === "No Choice" && (
          <LinuxFolderStructureNoChoice
            roundNum={roundNumberAssigned}
            autoAssignLFS={handleFolderData}
          />
        )}
        {difficultyConditionChosen === "No Choice" && (
          <TaskQANoChoice
            roundNum={roundNumberAssigned}
            assignTaskQA={handleTaskData}
          />
        )}

        {congruent == 3 ? <SystemVulnerabilitiesRand
          roundNum={roundNumberAssigned}
          assignVulnerabilities={handleVulnerabilitiesData} /> :
          <SystemVulnerabilities
            roundNum={roundNumberAssigned}
            assignVulnerabilities={handleVulnerabilitiesData} />}

        <button onClick={HandleGame}>Start Game</button>
      </div>
    </>
  );
};

export default Round;

/*
  const handleMyClick = (count) => {
    console.log("Handle Click event Fired from Parent", count);
  };

  const roundNumberAdd = () => {
    console.log(roundNumber);
    let x = roundNumber + 1;
    setRoundNumber(x);
    console.log(roundNumber);
  };

  const assignLinuxFolderStructure = (lFolder) => {
    setLFolderStructure(lFolder);
  };

  const testChecker = () => {
    console.log("testChecker");
    console.log(roundNumber);
    console.log(lFolderStructure);
  };

  const applyGameFolders = () => {
    console.log("apply game worked first");
    <LinuxFolderStructure
      roundNumber={roundNumber}
      autoAssignLFS={handleFolderData}
    />;
  };

  


        <button onClick={roundNumberAdd}>Add Round Number</button>
        <button onClick={testChecker}>Test</button>
*/
